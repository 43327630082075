/* noto-sans-tc-300 - latin */
@font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/noto-sans-tc-v26-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/noto-sans-tc-v26-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/noto-sans-tc-v26-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/noto-sans-tc-v26-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./fonts/noto-sans-tc-v26-latin-300.svg#NotoSansTC') format('svg'); /* Legacy iOS */
  }
  
  /* noto-sans-tc-regular - latin */
  @font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/noto-sans-tc-v26-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/noto-sans-tc-v26-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/noto-sans-tc-v26-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/noto-sans-tc-v26-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/noto-sans-tc-v26-latin-regular.svg#NotoSansTC') format('svg'); /* Legacy iOS */
  }
  
  /* noto-sans-tc-500 - latin */
  @font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/noto-sans-tc-v26-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/noto-sans-tc-v26-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/noto-sans-tc-v26-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/noto-sans-tc-v26-latin-500.woff') format('woff'), /* Modern Browsers */
         url('./fonts/noto-sans-tc-v26-latin-500.svg#NotoSansTC') format('svg'); /* Legacy iOS */
  }
  
  /* noto-sans-tc-700 - latin */
  @font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/noto-sans-tc-v26-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/noto-sans-tc-v26-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/noto-sans-tc-v26-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/noto-sans-tc-v26-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./fonts/noto-sans-tc-v26-latin-700.svg#NotoSansTC') format('svg'); /* Legacy iOS */
  }
  
  /* noto-sans-tc-900 - latin */
  @font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 900;
    src: url('./fonts/noto-sans-tc-v26-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/noto-sans-tc-v26-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/noto-sans-tc-v26-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/noto-sans-tc-v26-latin-900.woff') format('woff'), /* Modern Browsers */
         url('./fonts/noto-sans-tc-v26-latin-900.svg#NotoSansTC') format('svg'); /* Legacy iOS */
  }
  