$initial-transform: translateX(-50%);
$small-scale: 0.7;
$big-scale: 1.3;
$animation-time: 1.5s;

.spinner-wrapper {
	--circle-size: 25px; // use this variable to override spinner size
	--wrapper-padding: 25px; // use this variable to override padding
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--wrapper-padding);
}

.spinner-container {
	width: var(--circle-size);
	height: var(--circle-size);
	position: relative;
	z-index: 5;
}

.spinner-circle {
	position: absolute;
	display: block;
	width: 50%;
	padding: 50%;
	border-radius: 50%;
	mix-blend-mode: darken;
}

.spinner-circle-primary {
	background: rgb(var(--kynk-primary-spinner-color));
	animation: spinner-animation-primary $animation-time ease-in-out infinite;
}

.spinner-circle-secondary {
	background: rgba(var(--kynk-secondary-spinner-color));
	animation: spinner-animation-secondary $animation-time ease-in-out infinite;
}

@keyframes spinner-animation-primary {
	0% {
		transform: $initial-transform scale(1);
		left: 0;
	}
	25% {
		transform: $initial-transform scale($big-scale);
		left: 50%;
	}
	50% {
		transform: $initial-transform scale(1);
		left: 100%;
	}
	75% {
		transform: $initial-transform scale($small-scale);
		left: 50%;
	}
	100% {
		transform: $initial-transform scale(1);
		left: 0;
	}
}
@keyframes spinner-animation-secondary {
	0% {
		transform: $initial-transform scale(1);
		left: 100%;
	}
	25% {
		transform: $initial-transform scale($small-scale);
		left: 50%;
	}
	50% {
		transform: $initial-transform scale(1);
		left: 0;
	}
	75% {
		transform: $initial-transform scale($big-scale);
		left: 50%;
	}
	100% {
		transform: $initial-transform scale(1);
		left: 100%;
	}
}
