$default-fonts: "Noto Sans TC", Arial, Tahoma, sans-serif, "Segoe UI Emoji",
	"Noto Emoji";

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* Define Global Variables */
:root{
	--kynk-background: #000;
	--kynk-shadow-values: 0, 0, 0;
	--kynk-shadow: rgba(var(--kynk-shadow-values), 0.12);
	--kynk-font-color: rgb(214, 210, 205);
    --kynk-primary-spinner-color: 200, 0, 230;
    --kynk-secondary-spinner-color: 0, 216, 229;
	--kynk-bg-elevation-1: #232425;
	--kynk-bg-elevation-2: #373838;
	--shadows: none;
}

html,
body {
	overflow: hidden;
	background-color: var(--kynk-background);
}

body {
	font-family: $default-fonts;
    color: var(--kynk-font-color);
}

.primary-button {
	color: #ffffff;
	background-color: rgb(var(--kynk-primary-spinner-color));
	border-radius: 4px;
	padding: 8px;
	font-weight: 600;
	border: 1px solid rgb(var(--kynk-primary-spinner-color));

	&:disabled {
		border: 1px solid rgba(var(--kynk-primary-spinner-color), 0.06);
		background: rgba(var(--kynk-primary-spinner-color), 0.06);
		color: rgba(var(--kynk-primary-spinner-color), 0.34);
	}
}